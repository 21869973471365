<template>
  <Stack
    tag="div"
    direction="row"
    justify="center"
    align="center"
    gap="2xs"
    class="ui-max-w-[164px]"
  >
    <Button
      size="md"
      variant="neutral"
      @click="decreaseQuantity()"
      data-test-id="decreaseQuantityBtn"
      data-fs="decreaseQuantityBtn"
    >
      <span class="ui-sr-only">Reduce quantity</span>
      <Icon name="minus-thin" :size="16" />
    </Button>

    <Input
      :id="productID ? `quantityInput-${productID}` : 'quantityInput'"
      :label="productID ? `quantityInput-${productID}` : 'quantityInput'"
      :labelVisible="false"
      v-model="inputQuantity"
      :value="inputQuantity"
      type="number"
      :min="isTally ? 0 : 1"
      class="ui-text-center ui-min-w-2xl no-arrows"
      data-test-id="quantityInput"
      data-fs="quantityInput"
    />
    <Button
      size="md"
      variant="neutral"
      @click="increaseQuantity()"
      data-test-id="addQuantityBtn"
      data-fs="addQuantityBtn"
    >
      <span class="ui-sr-only">Increase quantity</span>
      <Icon name="plus-thin" :size="16" />
    </Button>
  </Stack>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import Stack from "../Layout/Stack/Stack.vue";
import Input from "../FormElements/Input/Input.vue";
import Button from "../Global/Button/Button.vue";
import Icon from "../Icon/Icon.vue";
import { CardQuantityProps } from "./types";

const props = withDefaults(defineProps<CardQuantityProps>(), {
  quantity: 1,
});

const inputQuantity = ref<number>(
  props.quantity || (props.tallySelector ? 0 : 1),
);

const emit = defineEmits<{
  (event: "update:quantity", value: number): void;
  (event: "updateTallyTotal", tally: number, quantity: number): void;
}>();

const decreaseQuantity = () => {
  const minQuantity = props.isTally ? 0 : 1;

  if (inputQuantity.value > minQuantity) {
    inputQuantity.value = Number(inputQuantity.value) - 1;
    emit("update:quantity", inputQuantity.value);
  }
};

const increaseQuantity = () => {
  inputQuantity.value = Number(inputQuantity.value) + 1;
  emit("update:quantity", inputQuantity.value);
};

watch(inputQuantity, (val: any) => {
  if (props.tallySelector) {
    emit(
      "updateTallyTotal",
      Number.parseFloat(String(props.tallySelector)),
      Number.parseInt(val),
    );
  } else {
    emit("update:quantity", inputQuantity.value);
  }
});

onMounted(() => {
  if (inputQuantity.value) {
    emit(
      "updateTallyTotal",
      Number.parseFloat(String(props.tallySelector)),
      inputQuantity.value,
    );
  }
});

watch(
  () => props.quantity,
  (val: any) => {
    inputQuantity.value = val;
  },
);
</script>
